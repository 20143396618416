<template>
  <v-container fluid>
    <v-row>
      <v-col cols='3'
             v-for='product in products'
             :key='product.id'>
        <v-hover>
          <template v-slot:default='{ hover }'>
            <v-card class='mx-auto round-card pointer'
                    max-width='344'
                    @click='selectProduct(product)'>
              <v-img :src='img(product)'
                     class='round-card'
                     aspect-ratio='1'
                     contain />
  
              <v-fade-transition>
                <v-overlay v-if='hover'
                           absolute
                           color='#9575CD'>
                  <span class='text-h5 font-weight-bold'>
                    {{ product.data.attributes.title }}
                  </span>
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>

    <!-- Modal new category -->
    <v-dialog transition='dialog-bottom-transition'
              v-model='dialog'
              max-width='900'>
      <v-card>
        <v-card-title class='deep-purple lighten-2 white--text'>
          Mettre à jour {{ selectedProduct.title }}
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols='3'
                   v-for='variant in selectedProduct.variants'
                   :key='variant.id'>
              <v-card class='mt-5'>
                <v-card-title>
                  {{ variant.data.attributes.variant_title }}
                </v-card-title>

                <v-card-text>
                  <v-select :items='states'
                            v-model='variant.data.attributes.tmpState'
                            required
                            item-text='name'
                            item-value='value'
                            hide-details='auto'
                            label='État' />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class='d-flex justify-end align-center'>
          <v-btn class='blue white--text ma-3'
                 @click='updateVariants'>
            Sauvegarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar success -->
    <v-snackbar v-model='snackbarSuccess'
                :timeout='2000'
                right
                color='deep-purple accent-4'
                elevation='24'>
      Mis à jour avec succès !
    </v-snackbar>

    <!-- Snackbar Error -->
    <v-snackbar v-model='snackbarError'
                :timeout='2000'
                right
                color='red accent-4'
                elevation='24'>
      Une erreur est survenue
    </v-snackbar>
  </v-container>
</template>

<script>
  export default {
    name: 'Edit',
    data: () => ({
      selectedProduct: { title: '', variants: [] },
      dialog: false,
      states: [{ value: 'enabled', name: 'activé' },
               { value: 'backordered', name: 'Rupture' },
               { value: 'disabled', name: 'désactivé' }],
      snackbarSuccess: false,
      snackbarError: false,
    }),
    props: {
      products: { type: Array, default: () => [] },
    },
    methods: {
      img(product) {
        return product.data.attributes.variants[0].data.attributes.images_urls[0];
      },
      selectProduct(product) {
        product.data.attributes.variants.map(v => v.data.attributes.tmpState = v.data.attributes.state)
        this.selectedProduct = product.data.attributes;
        this.dialog = true;
      },
      updateVariants() {
        this.$emit('updating');

        return Promise.all(this.selectedProduct.variants.map(variant => {
          return new Promise(resolve => {
            const params = { variant: { state: variant.data.attributes.tmpState } };

            this.axios.put(`/v1/admin/variants/${variant.data.id}`, params)
                      .then(() => {
                        this.axios.get(`/v1/admin/variants/${variant.data.id}`)
                                  .then(response => {
                                    variant.data.attributes = response.data.data.attributes;

                                    this.dialog = false;
                                    resolve();
                                  })
                      })
                      .catch(() => this.snackbarError = true);
          });
        })).then(() => {
          this.snackbarSuccess = true;
          this.$emit('updating');
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
  .v-card--link:focus:before {
      opacity: 0 !important;
  }

  .round-card {
    border-radius: 50% !important;
  }

  .pointer {
    cursor: pointer;
  }
</style>