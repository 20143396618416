<template>
  <v-container>
    <h2>Modèles</h2>
    <br>

    <Categories @selectCategory='selectCategory' />

    <v-row v-if='loading'>
      <v-col class='d-flex justify-center align-center'>
        <v-progress-circular indeterminate
                             :size='70'
                             :width='7'
                             :active='loading' />
      </v-col>
    </v-row>

    <v-row v-if='selectedCategory'
           class='mb-5'>
      <v-col class='d-flex align-center justify-center'>
        <v-switch v-model='displayDisabled'
                  label='Afficher les produits désactivés' />
      </v-col>
      <v-col class='d-flex align-center justify-center'>
        <v-switch v-model='editMode'
                  label='Mise à jour // Ordre' />
      </v-col>
    </v-row>

    <Edit :products='displayedProducts'
          @updating='updating'
          v-if='editMode' />
    <Order :products='displayedProducts'
           @updating='updating'
           @reload='fetchProducts'
           v-else />

    <v-overlay :z-index='500'
               :value='updatingLoader'>
      <v-progress-circular :size='200'
                           :width='10'
                           color='primary'
                           v-show='updatingLoader'
                           indeterminate />
    </v-overlay>
  </v-container>
</template>

<script>
  import Categories from './Categories.vue';
  import Edit from './components/Edit.vue';
  import Order from './components/Order.vue';

  export default {
    name: 'AdminProducts',
    components: { Categories, Edit, Order },
    data: () => ({
      selectedCategory: null,
      products: [],
      loading: false,
      displayDisabled: false,
      editMode: true,
      updatingLoader: false
    }),
    watch: {
      selectedCategory () { this.fetchProducts(); }
    },
    computed: {
      displayedProducts() {
        if(this.displayDisabled) {
          return this.products;
        } else {
          return this.products.filter(product => product.data.attributes.variants.every(e => ['enabled', 'backordered'].includes(e.data.attributes.state)));
        }
      }
    },
    methods: {
      fetchProducts() {
        return new Promise(resolve => {
          this.products = [];
          this.loading = true;

          this.axios.get('/v1/admin/products/', { params: { category_id: this.selectedCategory.id } })
                    .then(response => {
                      this.products = response.data;
                      this.loading = false;

                      resolve();
                    });
        });
      },
      selectCategory(category) {
        this.selectedCategory = category;
      },
      updating() {
        this.updatingLoader = !this.updatingLoader;
      }
    }
  }
</script>
