<template>
  <v-container fluid
               class='increased-height'>
    <draggable v-model='sortable'
               @start='drag=true'
               @end='drag=false'
               class='row'>
      <v-col cols='2'
             v-for='product in sortable'
             :key='product.id'>
        <v-hover>
          <template v-slot:default='{ hover }'>
            <v-card class='mx-auto'
                    max-width='200'>
              <v-img :src='img(product)'
                     aspect-ratio='1'
                     contain />
  
              <v-fade-transition>
                <v-overlay v-if='hover'
                           absolute
                           color='#B2EBF2'>
                  <span class='text-h5 font-weight-bold blue-grey--text text--darken-2'>
                    {{ product.data.attributes.title }}
                  </span>
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </draggable>

    <v-card class='sticky-card mt-10'>
      <v-card-actions class='d-flex justify-end align-center'>
        <v-btn @click='savePositions'
               class='green darken-1 white--text ma-3 font-weight-bold'
               large>
          Sauvegarder
        </v-btn>
      </v-card-actions>
    </v-card>



    <!-- Snackbar success -->
    <v-snackbar v-model='snackbarSuccess'
                :timeout='2000'
                right
                color='deep-purple accent-4'
                elevation='24'>
      Mis à jour avec succès !
    </v-snackbar>

    <!-- Snackbar Error -->
    <v-snackbar v-model='snackbarError'
                :timeout='2000'
                right
                color='red accent-4'
                elevation='24'>
      Une erreur est survenue
    </v-snackbar>
  </v-container>
</template>

<script>
  import draggable from 'vuedraggable'

  export default {
    name: 'Order',
    components: { draggable },
    data: () => ({
      snackbarSuccess: false,
      snackbarError: false,
      sortable: []
    }),
    props: {
      products: { type: Array, default: () => [] },
    },
    watch: {
      products: {
        handler() { this.sortable = this.products },
        deep: true
      }
    },
    mounted() {
      this.sortable = this.products;
    },
    methods: {
      img(product) {
        return product.data.attributes.variants[0].data.attributes.images_urls[0];
      },
      savePositions() {
        this.$emit('updating');

        return Promise.all(this.sortable.map((object, index) => {
          return new Promise(resolve => {
            const params = { product: { position: index } };

            this.axios.put(`/v1/admin/products/${object.data.id}`, params)
                      .then(() => resolve())
                      .catch(() => this.snackbarError = true);
          });
        })).then(() => this.reload());
      },
      reload() {
        this.snackbarSuccess = true;
        this.$emit('reload');
        this.$emit('updating');
      }
    }
  }
</script>

<style lang='scss' scoped>
  .v-card--link:focus:before {
      opacity: 0 !important;
  }

  .sticky-card {
    position: sticky;
    bottom: 0;
  }

  .increased-height {
    min-height: 100vh !important;
  }
</style>