<template>
  <v-row>
    <v-col>
      <v-container fluid>
        <v-expansion-panels v-model='panel'
                            multiple>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Catégories
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-row>
                <v-col v-for='item in categories'
                       :key='item.id'>
                  <v-card>
                    <v-card-title>
                      {{ item.data.attributes.title }}  
                    </v-card-title>

                    <v-card-text>
                      <ul>
                        <li v-for='child in item.data.attributes.children'
                            :key='child.id'>
                          <v-btn v-if='child.data.attributes.children.length === 0'
                                 @click='selectCategory(child)'
                                 :class='{ "blue white--text": child.data.id === (selectedCategory || {}).id }'>
                            {{ child.data.attributes.title }}
                          </v-btn>

                          <ul>
                            <li v-for='grandChild in child.data.attributes.children'
                                :key='grandChild.id'>
                              <v-btn @click='selectCategory(grandChild)'
                                     :class='{ "blue white--text": grandChild.data.id === (selectedCategory || {}).id }'>
                                {{ grandChild.data.attributes.title }}
                              </v-btn>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-row class='text-center mt-3 text-subtitle-1'>
          <v-col v-if='selectedCategory'>
            {{ selectedCategory.attributes.title }}
          </v-col>

          <v-col v-else>
            Pas de catégorie sélectionnée
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'Categories',
    data: () => ({
      selectedCategory: null,
      categories: [],
      panel: [0]
    }),
    async mounted() {
      await this.fetchCategories();
    },
    methods: {
      fetchCategories() {
        return new Promise(resolve => {
          this.axios.get('/v1/admin/categories')
                    .then(response => {
                      this.categories = response.data;

                      resolve();
                    });
        })
      },
      selectCategory(category) {
        this.selectedCategory = category.data;


        this.$emit('selectCategory', this.selectedCategory);
      }
    }
  }
</script>

<style lang='scss' scoped>
  li {
    margin: 10px;
  }
</style>